<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" v-if="!viLoader">
       <!-- <div class="dispflex">
      <h1 class="hdrtitle" style="margin-top: 0px;">Import / Export</h1>
       <button type="button" class="backbtn" @click="previousRoute">
          <i class="fa fa-arrow-left"></i>
        </button>
        </div> -->
        <div class="webNew-dispflex">
       
       <!-- <div class="backbox">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Import / Export
       </h4>
     </div>
      <div class="row mt-4">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 9px">
                  <i
                    class="fas fa-exchange-alt"
                    style="transform: rotate(90deg)"
                  ></i>
                </span>
                <h1>Upload Student data using Template</h1>
              </div>
            </div>
            <div class="p-3 widjetcontent">
              <div class="tabs" style="background: none">
                <input
                  type="radio"
                  name="tabs"
                  id="academictab"
                  checked="checked"
                />
                <label for="academictab">TEMPLATE</label>
                <div class="tab">
                  <div id="page-content">
                    <div class="downloadcont" style="margin:0 auto">
                      <div class="p-2">
                        <div class="alert alert-info m-3 mt-4">
                          <strong>Info!</strong> Use the "Student Upload Template" to bulk upload the student records.
                        </div>
                      </div>
                      <div class="downloadfilesect">
                        <ol>
                          <!-- https://vidhyaan.sgp1.digitaloceanspaces.com/Holiday.xlsx -->
                          <!-- <li> -->
                            <a href="https://vidhyaan.sgp1.digitaloceanspaces.com/Student%20Data%20Bulk%20Upload%20Template.xlsx" target="_blank" >Student Upload Template </a>
                          
                            <!-- <i class="fas fa-times"></i> -->
                          <!-- </li> -->
                        </ol>
                      </div>
                      <div class="downloadcontsect upload-btn-wrapper mt-5">
                        <button class="upbtn">Upload a file</button>
                           <input
                            type="file"
                            id="importExportID"
                            ref="schoolImagechangeFile"
                            name="schoolImagechangeFile"
                            accept=".xls,.xlsx"
                            @change="updateFile('schoolImage','schoolImagechangeFile')"/>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="radio" name="tabs" id="leads" />
                <label for="leads">MAP YOUR STUDENTS</label>
                <div class="tab">
                  <div id="page-content">
                   
                    <div class="row" style="margin-bottom: 1%">
                      <div class="col-md-4">
                       <span class="has-float-label">
                        <select
                          name="Class"
                          class="form-control form-input"
                          id="deptlistid"
                          @change="getMapToClassList"
                          v-model="mapToClass"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in mapToClassList"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}
                          </option>
                        </select>
                        <label for="Start">Map to Class </label>
                      </span>
                      </div>
                      <div class="col-md-4">
                      
                      <span class="has-float-label">
                        <select
                          name="Class"
                          class="form-control form-input"
                          id="deptlistid"
                           @change="getMapToClassList"
                          v-model="mapToSection"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in mapToSectionList"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}
                          </option>
                        </select>
                        <label for="Start">Map to Class Section </label>
                      </span>
                      </div>
                      <div class="col-md-4 text-right dk_iconsml">
                        <button
                          type="button"
                          class="btn btnsml"
                          id="svbtn"
                          @click.prevent="openPopUp" style="width:auto!important"
                        >
                            <span>Verify & Upload</span>
                        </button>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-sm-12">
                        <div>
                          <div class="restable">
                            <div class="resrow resheader">
                              <!-- <div class="cell" style="width: 10%">Select</div> -->
                              <div class="cell chkbox" data-title="Select">
                                <label class="custcheckbox">
                                  <input type="checkbox" v-model="selectAll" :disabled="studentList.length == 0" @input.prevent="selectAllStudents(selectAll)"  />
                                  <span class="checkmarkchk"></span>
                                </label>
                              </div>
                              <div class="cell" style="text-align: center">
                                Student Name
                              </div>
                              <div class="cell" style="text-align: center">
                                Dob
                              </div>
                              <div class="cell" style="text-align: center">
                                Gender
                              </div>
                              <div class="cell" style="text-align: center">
                                Email
                              </div>
                                <div class="cell" style="text-align: center">
                                phoneNo
                              </div>
                              <div class="cell" style="text-align: center">
                                Map To Class
                              </div>
                              <div class="cell" style="text-align: center">
                                Map To Section
                              </div>
                              <div class="cell" style="text-align: center">Action</div>
                            </div>
                            <div
                              class="resrow"
                              v-for="(student, index) in studentList"
                              :key="index"
                            >
                              <div
                                class="cell chkbox"
                                data-title="Select"
                                style="text-align: center"
                              >
                                <label class="custcheckbox">
                                  <input
                                    type="checkbox"
                                    v-model="student.isSelected"
                                  />
                                  <span class="checkmarkchk"></span>
                                </label>
                              </div>
                              <div
                                class="cell"
                                data-title="Student Name"
                                style="text-align: center"
                              >
                                {{ student.firstName + " " + student.lastName }}
                              </div>

                              <div
                                class="cell"
                                data-title="Class"
                                style="text-align: center"
                              >
                                {{ student.dob | dateFormat }}
                              </div>
                              <div
                                class="cell"
                                data-title="Class"
                                style="text-align: center"
                              >
                                {{ student.gender }}
                              </div>
                              <div
                                class="cell"
                                data-title="Class"
                                style="text-align: center"
                              >
                                {{ student.userDetails.email }}
                              </div>
                              <div
                                class="cell"
                                data-title="Class"
                                style="text-align: center"
                              >
                                {{ student.userDetails.phoneNo }}
                              </div>
                              <div
                                class="cell"
                                data-title="Class"
                                style="text-align: center"
                              >
                                {{ student.mapToClass ? student.mapToClass : '' }}
                              </div>
                              <div
                                class="cell"
                                data-title="Class"
                                style="text-align: center"
                              >
                                {{ student.mapToSection ? student.mapToSection : '' }}
                              </div>
                              <div class="cell" data-title="Action" style="text-align: center">
                             
                              <button class="btn round orange tooltipt" @click.prevent="openEditPopUp(index)">
                                  <i class="fas fa-edit"></i>
                                <span class="tooltiptext">Edit</span>
                              </button>

                               <button class="btn round red tooltipt"  @click="showDeleteModal(student._id)">
                                  <i class="far fa-trash-alt"></i> <span class="tooltiptext">Delete</span>
                                </button>

                            </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-center mt-4" v-if="studentList.length > 0">
                            <div class="pagination" id="pagination">
                              <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                              <!-- class="active"  -->
                              <a
                                v-for="(item, index) in lastPageNumber"
                                :key="index"
                                v-on:click="selected = item"
                                v-bind:class="{ active: selected == item }"
                                @click="navToSelectedPage(item)"
                                >{{ item }}</a
                              >
                              <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    <b-modal id="crtStudents" style="width: 100%">
      <button type="button" class="close"  @click.prevent="closePopUp">×</button>

      <div class="contact-form">
        <h2>Assigned</h2>
        <br />
        <div>
          <form data-vv-scope="departConfig" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    name="AcademicYear"
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="details.academicYear"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>

                  <label for="Format">Academic Year</label>
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    name="Class"
                    class="form-control form-input"
                    id="deptlistid"
                    @change="getSectionList(details.class)"
                    v-model="details.class"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>
                  <label for="Start">Class Name </label>
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    name="Section"
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="details.classSection"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in sectionList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label for="Prefix">Section </label>
                </span>
              </div>
            </div>
          </form>

          <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <!-- <div class="dk_icon"></div> -->
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                :disabled="
                  !details.academicYear ||
                  !details.class ||
                  !details.classSection || viLoader
                "
                @click.prevent="createContact"
              >
                  <span>Upload</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closePopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="editLead" style="width: 100%" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeEditPopUp">×</button>
      <div class="contact-form">
        <h2>Edit</h2>
        <br />
        <div>
          <form data-vv-scope="studentvalidate" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    v-validate="'required'"
                    name="studentFirstName"
                    v-model="editObj.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="First Name"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentFirstName')"
                    class="vi-error"
                    style="top:-1em"
                    >First name is required</label
                  >
                  <label for="stdFirstName" v-else
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    name="studentLastName"
                    v-model="editObj.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                    placeholder="Last Name"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentLastName')"
                    class="vi-error"
                    style="top:-1em"
                    >Last name is required</label
                  >
                  <label for="stdLastName" v-else
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="stdEmail"
                    v-model="editObj.email"
                    class="form-control form-input"
                    id="stdEmail"
                    placeholder="Secondary Email"
                  />
                  <label for="stdEmail"
                    >Secondary Email</label
                  >
                </span>
              </div>
              
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <!-- max="9999-12-31" -->
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="date"
                    onkeydown="return false"
                    name="DOB"
                    v-model="editObj.dob"
                    class="form-control form-input"
                    id="stdDob"
                    placeholder="Date of Birth"
                  />
                  <label
                    v-if="errors.first('studentvalidate.DOB')"
                    class="vi-error"
                    style="top:-1em"
                    >Date of birth is required</label
                  >
                 
                 
                  <label for="stdDob" v-else
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
              
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    v-validate="'required'"
                    name="Blood Group"
                    class="form-control form-input"
                    id="bloodGroup"
                    v-model="editObj.bloodGroup"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(blood, index) in bloodGroupList"
                      :key="index"
                      :value="blood"
                    >
                      {{ blood }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('studentvalidate.Blood Group')"
                    class="vi-error"
                    style="top:-1em"
                    >Blood group is required</label
                  >
                  <label for="bloodGroup" v-else
                    >Blood Group <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                    <span class="has-float-label">
                      <select
                        v-validate="'required'"
                        name="gender"
                        class="form-control form-input"
                        id="gender"
                        v-model="editObj.gender"
                      >
                        <option :value="null">-- Select --</option>
                        <option
                          v-for="(item, index) in genderList"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                      <label
                        v-if="errors.first('studentvalidate.gender')"
                        class="vi-error"
                        style="top:-1em"
                        >Gender is required</label
                      > 
                      <label for="firstName" v-else
                        >Gender <span class="required">*</span></label
                      >
                    </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="editObj.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.pointOfContact')"
                            class="vi-error"
                            style="top:-1em"
                            >Point of contact is required</label
                          > 
                          <label for="firstName" v-else
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label" v-if="editObj.pointOfContact == 'Father'">
                          <input
                            type="text"
                            name="fthrname"
                            v-validate="'required'"
                            v-model="editObj.userDetails.firstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthrname')"
                            class="vi-error"
                            style="top:-1em"
                            >Father First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father First Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="editObj.pointOfContact == 'Mother'">
                          <input
                            type="text"
                            name="Mother"
                            v-validate="'required'"
                            v-model="editObj.userDetails.firstName"
                            class="form-control form-input"
                            id="Mother"
                            placeholder="Mother First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.Mother')"
                            class="vi-error"
                            style="top:-1em"
                            >Mother First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother First Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="editObj.pointOfContact == 'Guardian'">
                          <input
                            type="text"
                            name="Guardian"
                            v-validate="'required'"
                            v-model="editObj.userDetails.firstName"
                            class="form-control form-input"
                            id="Guardian"
                            placeholder="Guardian First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.Guardian')"
                            class="vi-error"
                            style="top:-1em"
                            >Guardian First Name is required</label
                          >
                          <label for="Guardian" v-else
                            >Guardian First Name <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label" v-if="editObj.pointOfContact == 'Father'">
                          <input
                            type="text"
                            name="mthrname"
                            v-validate="'required'"
                            v-model="editObj.userDetails.lastName"
                            class="form-control form-input"
                            id="mthrnameid"
                            placeholder="Father Last Name"
                          />
                           <label
                            v-if="errors.first('studentvalidate.mthrname')"
                            class="vi-error"
                            style="top:-1em"
                            >Father Last Name is required</label
                          >
                          <label for="mthrnameid" v-else
                            >Father Last Name <span class="required">*</span></label
                          >
                        </span>
                        <span class="has-float-label" v-if="editObj.pointOfContact == 'Mother'">
                          <input
                            type="text"
                            name="mthrname"
                            v-validate="'required'"
                            v-model="editObj.userDetails.lastName"
                            class="form-control form-input"
                            id="mthrnameid"
                            placeholder="Mother Last Name"
                          />
                           <label
                            v-if="errors.first('studentvalidate.mthrname')"
                            class="vi-error"
                            style="top:-1em"
                            >Mother Last Name is required</label
                          >
                          <label for="mthrnameid" v-else
                            >Mother Last Name <span class="required">*</span></label
                          >
                        </span>
                        <span class="has-float-label" v-if="editObj.pointOfContact == 'Guardian'">
                          <input
                            type="text"
                            name="mthrname"
                            v-validate="'required'"
                            v-model="editObj.userDetails.lastName"
                            class="form-control form-input"
                            id="mthrnameid"
                            placeholder="Guardian Last Name"
                          />
                           <label
                            v-if="errors.first('studentvalidate.mthrname')"
                            class="vi-error"
                            style="top:-1em"
                            >Guardian Last Name is required</label
                          >
                          <label for="mthrnameid" v-else
                            >Guardian Last Name <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <!-- <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label" >
                          <input
                            name="mtEmail"
                            v-validate="'required'"
                            class="form-control form-input"
                            id="mthrnameid"
                          />
                           <label
                            v-if="errors.first('studentvalidate.mtEmail')"
                            class="vi-error"
                            style="top:-1em"
                            >Primary Email is required</label
                          >
                          <label for="mthrnameid" v-else
                            >Primary Email <span class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label" >
                          
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            v-validate="'required|numeric|min:10|max:10'"
                            name="ptph"
                            v-model="editObj.userDetails.phoneNo"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Phone"
                          />
                           <label
                            v-if="errors.first('studentvalidate.ptph')"
                            class="vi-error"
                            style="top:-1em"
                            >Please enter a valid mobile number(10 digits)</label
                          >
                          <label for="mthrnameid" v-else
                            >Mobile <span class="required">*</span></label
                          >
                        </span>
                        
                      </div> -->
            </div>
          </form>

          <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <!-- <div class="dk_icon"></div> -->
            <div class="text-center dk_iconsml">
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="updateStudent"
               
              >
                  <span>Update</span>
              </button>

              <button
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="closeEditPopUp"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
     <b-modal id="deleteLeadPopup" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmDelete"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="duplicates" style="width: 4%" size="sm" no-close-on-backdrop no-close-on-esc >
      <button type="button" class="close"  @click.prevent="closeDuplicatesPopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Duplicate Emails/PhoneNumbers</h2>
        <br />
         <div class="form-row" v-if="duplicateMailIds.length > 0">
              <div class="col-lg-12">
              <div class="widjet">
                <div class="alert alert-info mb-2"><strong>Info!</strong> Email already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.</div>
                <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell" style="width: 10%">S.No</div>
                      <div class="cell">Email</div>
                    </div>

                    <div
                      class="resrow"
                      v-for="(data, index) in duplicateMailIds"
                      :key="index"
                    >
                      
                      <div class="cell" data-title="From Month">
                        {{ index + 1 }}
                      </div>
                      <div class="cell" data-title="From Month">
                        {{ data }}
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
        </div>
         </div>
        <div class="form-row" v-if="duplicatePhoneNumbers.length > 0">
              <div class="col-lg-12">
              <div class="widjet">
               <div class="alert alert-info mb-2"><strong>Info!</strong> Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.</div>
                <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell" style="width: 10%">S.No</div>
                      <div class="cell">Mobile No</div>
                    </div>

                    <div
                      class="resrow"
                      v-for="(data, index) in duplicatePhoneNumbers"
                      :key="index"
                    >
                      
                      <div class="cell" data-title="From Month">
                        {{ index + 1 }}
                      </div>
                      <div class="cell" data-title="From Month">
                        {{ data }}
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
        </div>
         </div>
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
         
          <button @click.prevent="closeDuplicatesPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Close</span>
          </button>
        </div>
      </div>
     
    </b-modal>
    <b-modal id="requiredFieldsPopUp" style="width: 4%" size="sm" no-close-on-backdrop no-close-on-esc >
      <button type="button" class="close"  @click.prevent="closeRequiredFieldsPopUp">×</button>
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>{{ requiredMessage }}</h2>
        <br />
         <div class="form-row">
              <div class="col-lg-12">
              <div class="widjet">
                <div class="tbllist mt-1">
                  <div class="restable">
                    <div class="resrow resheader">
                      <!-- <div class="cell" style="width: 10%">S.No</div> -->
                      <div class="cell">Row No</div>
                      <div class="cell">Missing / Invalid </div>
                    </div>

                    <div
                      class="resrow"
                      v-for="(data, index) in Object.keys(requiredFields)" :key="index"
                    >
                      
                      <div class="cell" data-title="From Month">
                        {{ data }} row
                      </div>
                       <div class="cell" data-title="From Month">
                        {{ getRequiredValues(requiredFields[data]) }}
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
        </div>
         </div>
       
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
         
          <button @click.prevent="closeRequiredFieldsPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Close</span>
          </button>
        </div>
      </div>
     
    </b-modal>
    </div>
    <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
    />
    
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "Class",
  
  data() {
    return {
      details: {
        academicYear: null,
        class: null,
        classSection: null,
      },
      editObj:{},
      editId: null,
      deleteId: null,
      selectAll: false,
      classList: [],
      academicYearList: [],
      sectionList: [],
      studentList: [],
      duplicateMailIds: [],
      duplicatePhoneNumbers: [],
      requiredFields: {},
      requiredMessage: '',
      mapToClass: null,
      mapToSection: null,
      countAll: true,
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      skip: 0, // set the skip info
      limit: 50, // set the limit info
      mapToClassList: [],
      mapToSectionList: [],
      pointOfList: [
       'Father',
       'Mother',
       'Guardian'
      ],
      genderList: [
       'Male',
       'Female'
      ],
      bloodGroupList: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"],
      viLoader: false,

    };
  },
  created() {
    this.getAcademicYearList();
    this.getClassList();
    this.getImportData('new');
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    countSelectedStudents() {
     if(this.studentList.length > 0){
       const selectedCount = this.studentList.filter( x => x.isSelected)
	     return selectedCount.length
	 }else{
		 return 0
	 }}
  },
  methods: {
     previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
     this.$router.push({ path: data });
    },
    getRequiredValues(data){
      if(data.length > 0){
        return data.map( x => x.missing)
      }else{
        return ''
      }
    },
    openEditPopUp(index){
      const details = this.studentList[index]

      this.editObj = details

      if(this.editObj.dob){
        this.editObj.dob = this.editObj.dob.substring(0,10);
      }
      this.editId  = this.editObj._id,

     this.$bvModal.show("editLead");
    },
    closeEditPopUp(){
     this.$bvModal.hide("editLead");
    },
    async updateStudent() {
       let isFormValid = false;

       await this.$validator.validateAll('studentvalidate').then(result => {
          isFormValid = result;
       });

      if (isFormValid) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
         const response = await ViService.viXPut(
          `/importAndExport/updateLead/${this.editId}`,
          this.editObj,
          userData.token
        );
       
        if (response.isSuccess) {
          this.closeEditPopUp();

          this.getImportData('old');

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }else{
        this.$toasted.error('Please fill all the required details');
      }
    },
    async navToSelectedPage(selectedPageNo) {

      this.skip = (this.limit * (selectedPageNo - 1));

      this.countAll = false

      this.getImportData('old');
    },
     async confirmDelete() {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
       this.$router.push('/login');
      } else {
        const response = await ViService.viDelete(`/importAndExport/deleteLead/${this.deleteId}`,userData.token);
        if (response.isSuccess) {
          this.$bvModal.hide("deleteLeadPopup");
          this.deleteId = null;
          this.getImportData('old');

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },

     closeDeletePopUp(){
      this.deleteId = null
      this.$bvModal.hide("deleteLeadPopup");
     },

    getMapToClassList(){
    
    this.skip = 0
    this.currentPageNumber = 1,
    this.lastPageNumber = 1,
    this.selected = 1,
    this.totalCount = 0,
    this.countAll = true
    this.getImportData('old');

    },
    showDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show("deleteLeadPopup");
    },
    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getImportData('old');
    },

    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getImportData('old');
    },
    openPopUp() {
      const selectedCount = this.studentList.filter( x => x.isSelected)
      
      if(selectedCount.length > 0){
       this.details.academicYear = null;
       this.details.class = null;
       this.details.classSection = null;
       this.$bvModal.show("crtStudents");
      }else{
       this.$toasted.error('Please select any one student..');
      }
      
    },
    closePopUp() {
      this.$bvModal.hide("crtStudents");
      this.details.academicYear = null;
      this.details.class = null;
      this.details.classSection = null
    },
    downloadTemplate(){
 
    const anchor = document.createElement('a');
    anchor.href = '';
    anchor.target = '_blank';
    anchor.download = 'Student Template.csv';
    anchor.click();
    },

    selectAllStudents(value){

      const cloneList = [ ...this.studentList ]

     if(value){
       for(let x of cloneList){
          x.isSelected = false;
       }
       this.studentList = cloneList
		   
	   }else{
        for(let x of cloneList){
          x.isSelected = true;
        }

        this.studentList = cloneList
    	}		
  	},
    async createContact() {
          let userData = secureUI.sessionGet("user");

        if (!userData) {
         this.$toasted.error("Please login and do the action");
        } else {
          this.viLoader = true;


         const classDetails = this.classList.find((x) => x._id == this.details.class);

       
           
          let obj = {
            contactList: this.studentList.filter( x => x.isSelected),
            details: this.details,
            appliesClassName: classDetails.className
          }

          const response = await ViService.viXPost(
            "/importAndExport/convertLeadToStudent",obj,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
             let getCounts = this.$store.getters.getAdmissionObjCounts
            getCounts.student = getCounts.student + obj.contactList.length

            this.$store.dispatch('setAdmissionObjCOunt', getCounts);
            this.getImportData('old')
            this.selectAll = false

           

            this.closePopUp()
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
       
        }
        this.viLoader = false;

     
    },
    async updateFile(type,docType) {
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0]

        const fd = new FormData();

        fd.append('doc', docFileDetails, docFileDetails.name,docFileDetails.actType);
        this.viLoader = true;

        const response = await ViService.viPutOnly('/document/importFile',fd,userData.token);

        if (response.isSuccess) {
          if(!response.type){
          this.duplicateMailIds = [],
          this.duplicatePhoneNumbers =  []

          this.viLoader = false;
          
          this.duplicateMailIds = response.data.mailIds || [],
          this.duplicatePhoneNumbers = response.data.phoneNumbers || []

          if(this.duplicateMailIds.length > 0 || this.duplicatePhoneNumbers.length > 0){
            this.$bvModal.show("duplicates"); 
          }

          if(response.message == 'Upload Successful'){
            this.$toasted.success(response.message);
            this.getImportData('old');
          }else{
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
         
          }

          if(response.type){

           let requiredFiledData = response.data.reduce((r,data) => {
                r[data.row] = [...r[data.row] || [], data];
                return r;
            }, {});
            
           this.requiredMessage = response.message

           this.requiredFields = requiredFiledData || {}

           this.$bvModal.show("requiredFieldsPopUp"); 
           
          }
        } else {
           this.viLoader = false;
          this.$toasted.error(response.message);
        }
        this.viLoader = false;
        document.getElementById("importExportID").value=null; 

      }

    },
    closeDuplicatesPopUp(){
      this.$bvModal.hide("duplicates"); 
    },
    closeRequiredFieldsPopUp(){
      this.$bvModal.hide("requiredFieldsPopUp"); 
    },
    async getSectionList(classId) {
      if (classId) {
        this.sectionList = [];
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push('/login');
        } else {
          const response = await ViService.viXGet(
            `/contact/getSectionList?class=${classId}`,
            userData.token
          );
          if (response.isSuccess) {
            this.sectionList = secureUI.secureGet(response.data);

            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    async getClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.classList = [];
        const response = await ViService.viXGet(
          "/contact/getClassList",
          userData.token
        );
        if (response.isSuccess) {
          this.classList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getImportData(type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        if(type == 'new') {
         this.viLoader = true;
       }
        const response = await ViService.viXGet(
          `/importAndExport/getImportData?class=${this.mapToClass}&section=${this.mapToSection}&skip=${this.skip}&limit=${this.limit}&isCount=${this.countAll}`,
          userData.token
        );
        if (response.isSuccess) {
           const resultData = secureUI.secureGet(response.data);
           
           this.studentList = resultData.studentList
         
           if(this.countAll){
            this.totalCount = resultData.totalCount
            this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
           }
           const mapByClassList = resultData.mapByClass
           const mapToClass = []
           const mapToSection = []

      
           if(mapByClassList.length > 0){
             for(let x of mapByClassList){
              if(x.mapToClass && x.mapToClass != null && x.mapToClass != undefined && x.mapToClass != ''){
                mapToClass.push(x.mapToClass)
              }
              
              if(x.mapToSection && x.mapToSection != null && x.mapToSection != undefined && x.mapToSection != ''){
                mapToSection.push(x.mapToSection)
              }

             }

             if(mapToClass.length > 0){
              this.mapToClassList =  mapToClass.filter((item, index) => mapToClass.indexOf(item) === index);
             }

             if(mapToClass.length > 0){
              this.mapToSectionList =  mapToSection.filter((item, index) => mapToSection.indexOf(item) === index);
             }

           }
          
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
         this.viLoader = false
      }
    },
  
    async importData() {
      let userData = secureUI.sessionGet("user");
      const response = await ViService.viXPost(
        "/importAndExport/import/data",
        {
          contactDetails: [],
        },
        userData.token
      );
    },
  },
  components: {
      ViSpinner
    }
};
</script>